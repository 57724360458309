import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';
const { wpUrl } = window.cccisd.appDefs.app;
const Fortress = window.cccisd.fortress;

const AppHeader = () => {
    const guestNavLinks = [
        {
            url: `${wpUrl}/`,
            label: 'Home',
        },
        { url: `${wpUrl}/contact`, label: 'Contact' },
    ];

    const teacherNavLinks = [
        {
            url: `${wpUrl}/teacher-home/`,
            label: 'Teacher Home',
        },
        {
            url: `${wpUrl}/teacher-professional-development`,
            label: 'Professional Development',
        },
        { url: `/myCheckUpFeedback`, label: 'My Check-Up Feedback' },
        { url: `/checkUpMenu`, label: 'Check-Up Menu' },
        { url: '/resourceCenterTeacher', label: 'Resources' },
    ];

    const coachNavLinks = [
        {
            url: `/`,
            label: 'Coaches',
            children: [
                { url: `${wpUrl}/coach-home`, label: 'Coach Home' },
                { url: `${wpUrl}/coaching-process`, label: 'Coaching Process' },
                { url: `${wpUrl}/teacher-home`, label: 'Teacher Home' },
                {
                    url: `${wpUrl}/coach-professional-development`,
                    label: 'Professional Development',
                },
            ],
        },
        { url: `/myTeachers`, label: 'My Teachers' },
        { url: `/checkUpFeedback/:id`, label: 'Check-Up Feedback' },
        { url: `/checkUpMenu`, label: 'Check-Up Menu' },
        { url: '/resourceCenterCoach', label: 'Resources' },
    ];

    const uberCoachNavLinks = [
        {
            url: `/`,
            label: 'Coaches',
            children: [
                { url: `${wpUrl}/coach-home`, label: 'Coach Home' },
                { url: `${wpUrl}/coaching-process`, label: 'Coaching Process' },
                { url: `${wpUrl}/teacher-home`, label: 'Teacher Home' },
                {
                    url: `${wpUrl}/coach-professional-development`,
                    label: 'Professional Development',
                },
            ],
        },
        { url: `/myTeachers`, label: 'My Teachers' },
        { url: `/checkUpFeedback/:id`, label: 'Check-Up Feedback' },
        { url: `/checkUpMenu`, label: 'Check-Up Menu' },
        { url: '/resourceCenterCoach', label: 'Resources' },
        // ubercoach
        { url: `/data`, label: 'View Data' },
        { url: '/siteContent', label: 'Edit Site Content' },
        { url: '/appdefs/logs', label: 'Logs' },
        { url: '/manage', label: 'Manage' },
    ];

    const adminNavLinks = [
        {
            url: `${wpUrl}/admin-home/`,
            label: 'Admin Home',
            children: [
                { url: `${wpUrl}/admin-home/`, label: 'Admin Home' },
                { url: `${wpUrl}/coach-home/`, label: 'Coach Home' },
                { url: `${wpUrl}/teacher-home/`, label: 'Teacher Home' },
            ],
        },
        { url: `/myTeam`, label: 'My Team' },
        { url: `/checkUpMenu`, label: 'Check-Up Menu' },
        { url: '/resourceCenterCoach', label: 'Resources' },
    ];

    const uberNavLinks = [
        {
            url: `/checkUpMenu`,
            label: 'Check-Up Menu',
        },
        { url: `/resourceCenterCoach`, label: 'Coach Resources' },
        { url: `/resourceCenterTeacher`, label: 'Teacher Resources' },
        { url: `/data`, label: 'View Data' },
        { url: '/siteContent', label: 'Edit Site Content' },
        { url: '/manage', label: 'Manage' },
    ];

    const getNavLinks = () => {
        let links;
        const isUberCoach = Fortress.user.acting.role.handle === 'coach' && Fortress.user.acting.role_data.ubercoach;

        switch (Fortress.user.acting.role.handle) {
            case 'uberadmin':
                links = uberNavLinks;
                break;
            case 'coach':
                links = isUberCoach ? uberCoachNavLinks : coachNavLinks;
                break;
            case 'teacher':
                links = teacherNavLinks;
                break;
            case 'admin':
                links = adminNavLinks;
                break;
            case 'guest':
                links = guestNavLinks;
                break;
            default:
            // code block
        }

        return links;
    };
    return (
        <div className={style.wrapper}>
            <Header
                primaryNavLinks={getNavLinks()}
                containerClassName="container-fluid"
                showHomeAction={false}
                showAlertAction={false}
                showAdminAction={
                    ['uberadmin', 'questprojectadmin'].includes(Fortress.user.acting.role.handle) ? true : false
                }
                logoClassName={style.logo}
                logoLink={wpUrl}
            />
        </div>
    );
};

export default AppHeader;
